<script>
import ShareModal from '@/components/general/ShareModal'
import ImageCardType from '@/components/general/ImageCardType'
export default {
  name: 'ModalListDetails',
  components: {
    ImageCardType,
    ShareModal,
    ContentCardWide: () => import('@/components/contents/ContentCardWide'),
    ConfirmationModal: () => import('../general/ConfirmationModal'),
    ModalForbiddenResource: () => import('@/components/general/ModalForbiddenResource')
  },
  data () {
    return {
      showConfirmToggleConclusion: false,
      loading: false,
      list: null,
      contentLevels: ['interest', 'basic', 'intermediate', 'advanced'],
      contents: [],
      showContent: true,
      showAllSkills: false,
      showNotAllowedModal: false,
      actionOptions: ['edit', 'remove'],
      showRemovalModal: false,
      contentToBeRemoved: null,
      shareModal: false
    }
  },
  props: {
    listIdProp: {
      type: Object
    }
  },
  computed: {
    selectedListId () {
      return this.$route.params.listId
    },
    getDefaultCoverImage () {
      return '/assets/images/background-article.png'
    },
    getFirstSkills () {
      return this.list.skills.slice(0, 6)
    },
    getAllSkills () {
      return this.list.skills
    },
    getLanguages () {
      const languages = this.removeDuplicatesFromArray(this.contents.map(content => content.languageAlias))
      const getJunction = (index, arr) => {
        if (index === 0) return ''
        if (index === arr.length - 1) return ` ${this.$t('global.conjunction:and')}`
        return ', '
      }
      return languages.reduce((accLng, currLng, index, arr) => {
        return `${accLng}${getJunction(index, arr)} ${this.$t(`global.language:${currLng.toLowerCase()}`)}`
      }, '')
    },
    remainingSkills () {
      return this.list.skills.slice(6).length
    },
    highestLevel () {
      if (!this.contents || !this.contents.length) return 'no.level'
      let highLevel = 'interest'
      for (const content of this.contents) {
        if (content.level === 'advanced') {
          highLevel = content.level
          break
        } else if (this.contentLevels.indexOf(content.level) > this.contentLevels.indexOf(highLevel)) {
          highLevel = content.level
        }
      }
      return highLevel
    },
    ownerAvatarUrl () {
      return this.list.owner && this.list.owner.imageUrl ? this.list.owner.imageUrl : this.defaultAvatarUrl('profile')
    },
    ownerName () {
      return `${this.list.owner.name} ${this.list.owner.lastName || ''}`
    }
  },
  methods: {
    hideModal () {
      this.showContent = false
    },
    showModal () {
      this.showContent = true
    },
    executeAction (action) {
      const params = { listId: this.list.id }
      switch (action) {
        case 'edit':
          this.$router.push({ name: `${this.$route.name}.lists.manage`, params })
          this.showContent = false
          break
        case 'remove':
          this.showRemovalModal = true
          break
      }
    },
    removeList () {
      this.$store.dispatch('attemptRemoveList', this.list.id)
        .then(() => {
          this.$store.dispatch('attemptGetMyselfLists')
          this.$store.dispatch('attemptGetMyselfContents')
          this.$router.push(this.getBackRoute())
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('list:toast.delete.error')
          })
        })
        .finally(() => {
          this.showRemovalModal = false
        })
    },
    removeContentFromList () {
      this.$store.dispatch('attemptRemoveContentFromList', { contentId: this.contentToBeRemoved, listId: this.selectedListId })
        .then(() => {
          this.getListContent()
          this.$store.dispatch('attemptGetMyselfLists')
          this.$store.dispatch('attemptGetMyselfContents')
        })
        .finally(() => {
          this.contentToBeRemoved = null
        })
    },
    getListContent () {
      this.$store.dispatch('attemptGetContentsByList', this.list.id)
        .then(({ data }) => {
          this.contents = data
        })
    },
    setup () {
      this.loading = true
      const params = this.$route.params.listId === undefined ? this.listIdProp.listId : this.$route.params.listId

      this.$store.dispatch('attemptGetListById', params)
        .then(({ data }) => {
          this.list = { ...data, inProgress: false, isConcluded: false }
          this.list.contentsProgress = this.list.contents.filter(list => list.interactionType === 'conclusion').length / this.list.contents.length * 100
          this.getListContent()
        })
        .catch((err) => {
          if (err.code === 403 && err.error === 'not_allowed') {
            this.showNotAllowedModal = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close () {
      if (this.$route.params.listId !== undefined) this.$router.push(this.getBackRoute())
      if (!this.$route.query) {
        this.$router.push(this.getBackRoute())
      } else {
        this.$emit('close')
        this.$emit('showModal')
      }
    },
    setContentToBeRemoved (contentId) {
      this.contentToBeRemoved = contentId
    },
    applyToggleListConclusion () {
      // this.$store.dispatch('attemptToggleListConclusion', this.list.id)
      //   .then(({ data }) => {
      //     if (data) {
      //       this.list.isConcluded = !this.list.isConcluded
      //       this.$store.dispatch('attemptUpdateContentItem', this.list)
      //     }
      //     this.$store.dispatch('attemptSetFeedbackMsg', {
      //       type: 'success',
      //       title: this.$t(
      //         `list.conclusion.toggle:success.${
      //           this.list.isConcluded ? 'done' : 'undo'
      //         }`
      //       )
      //     })
      //   })
      //   .finally(() => {
      //     this.toggleConclusionModal()
      //     if (this.list.inProgress) {
      //       this.list.inProgress = !this.list.isConcluded
      //     }
      //   })

      // TO BE DELETED
      this.list.isConcluded = true
      if (this.list.inProgress) {
        this.list.inProgress = false
      }
      this.toggleConclusionModal()
    },
    favoriteList () {
      this.$store.dispatch('attemptToggleFavorite', this.list.id)
        .then(({ data }) => {
          if (data) {
            this.list.isFavorite = !this.list.isFavorite
            !this.list.isFavorite && this.$store.commit('toggleUserFavoriteList', this.list.id)
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t(`user.favorite.list.toggle:${this.list.isFavorite ? 'favorited' : 'unfavorited'}`),
              description: this.$t(`user.favorite.list.toggle:${this.list.isFavorite ? 'favorited' : 'unfavorited'}.description`)
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('attemptGetMyselfFavoriteLists')
        })
    },
    handleKey (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    handleOutsideClick () {
      this.close()
    },
    toggleInProgress () {
      this.$store
        .dispatch('attemptSetListInteraction', { listId: this.list.id, interaction: 'in_progress' })
        .then(({ data }) => {
          this.list.interactionType = data.currentStatus
        })
        .finally(() => {
          this.updateLearningInProgress()
        })
    },
    toggleConclusionModal () {
      this.showConfirmToggleConclusion = !this.showConfirmToggleConclusion
    },
    applyToggleContentConclusion () {
      this.$store
        .dispatch('attemptSetListInteraction', { listId: this.list.id, interaction: 'conclusion' })
        .then(({ data }) => {
          if (data) {
            this.list.interactionType = data.currentStatus
          }
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(
              `content.conclusion.toggle:success.${
                this.list.interactionType === 'conclusion' ? 'done' : 'undo'
              }`
            )
          })
        })
        .finally(() => {
          this.toggleConclusionModal()
        })
    },
    showShareModal () {
      this.shareModal = true
    },
    closeShareModal () {
      this.shareModal = false
    },
    shareDetails () {
      return {
        link: this.getBaseUrl() + `/discovery/list/details/${this.list.id}`
      }
    }
  },
  created () {
    this.setup()
  }
}
</script>

<template>
<div>
  <modal-forbidden-resource v-if="showNotAllowedModal" />
  <v-dialog v-else-if="list" :value="true" width="800" persistent no-click-animation content-class="v-dialog-list-detail" @click:outside="handleOutsideClick" @keydown="handleKey">
    <div v-if="loading" />
    <v-card v-else class="list-details--wrapper" :class="{ 'hide-content': !showContent }">
      <span class="top-right fixed black-shallow"><v-btn icon @click="close" color="#FFF"><v-icon>mdi-close</v-icon></v-btn></span>
      <div class="list-details--image-wrapper">
        <div class="list-details--image-overlay"></div>
        <v-img
          height="327px"
          :src="list.coverImageURL || getDefaultCoverImage">
        </v-img>
      </div>
      <v-container class="list-details-header pa-5 pr-15">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-chip
              color="#FFF"
              class="h7 list-chip"
            >
              <v-icon left>
                mdi-text-box
              </v-icon>
              {{ $tc('global:list', 1) }}
            </v-chip>
          </v-col>
          <div v-if="list.contents && list.contents.length" class="list-details--insights-progress-wrapper">
            <span class="list-details--insights-progress-percent">
              {{ $t('list.details:completed', { percent: Math.round(list.contentsProgress) , fraction: list.contents.filter(l => l.interactionType === 'conclusion').length + '/' + list.contents.length } ) }}
            </span>
            <v-progress-linear
              :value="list.contentsProgress"
              class="list-details--insights-progress"
              height="8"
              rounded
              background-color="#DBD5CE"
              color="#1200D3"
            ></v-progress-linear>
        </div>
        <v-col cols="12" sm="12" md="12">
            <h5 class="list-details--title h6">{{ longTextTreatment(list.title, isMobile ? 30 : 50) }}</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-avatar class="ml-3" size="36" color="indigo">
            <v-img :src="ownerAvatarUrl"/>
          </v-avatar>
          <span class="body--1 neutral-darkest list-details--owner-name ml-3">{{ ownerName }}</span>
        </v-row>
      </v-container>
      <v-container class="list-details--body-wrapper mt-2">
        <v-row class="list-details--actions-wrapper">
          <v-col cols="12" sm="12" md="12" class="list-details--infos-wrapper px-5">
            <div class="list-details--infos">
              <v-icon color="black">
                mdi-clock-outline
              </v-icon>
              <p class="lato font-16 neutral-darkest">{{ totalDurationByMs(list.duration) }}</p>
            </div>
            <div class="list-details--infos">
              <v-icon color="black">
                mdi-wrench
              </v-icon>
              <p class="body--1 neutral-darkest">
                {{ $t(`skill.modal.levels:${highestLevel}`) }}
              </p>
            </div>
            <div v-if="getLanguages && getLanguages.trim().length" class="list-details--infos">
              <v-icon color="black">
                mdi-web
              </v-icon>
              <p class="body--1 neutral-darkest">
                {{ getLanguages }}
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="list-details--actions-btn-wrapper">
          <v-btn v-if="list.isOwner && false" outlined class="btn-outlined-primary btn ml-2 mb-2">
            <v-icon left>
              mdi-plus-box-multiple-outline
            </v-icon>
            {{ $t('global:add.to.goal') }}
          </v-btn>
          <v-btn v-else :outlined="!list.isFavorite && !list.isOwner" @click="favoriteList" class="btn ml-2 mb-2" :color="list.isFavorite ? '#16AC99' : '#1200D3'">
            <v-icon left :class="{'white--text': list.isFavorite}">
              {{list.isFavorite ? 'mdi-heart' : 'mdi-heart-outline'}}
            </v-icon>
            <span :class="{'white--text': list.isFavorite}">{{ list.isFavorite ? $t('global:saved.list') : $t('global:save.list') }}</span>
          </v-btn>
          <v-btn
            class="btn ml-2 mb-2"
            :class="{'btn-primary btn-outlined': list.interactionType !== 'in_progress', 'btn-success-medium': list.interactionType === 'in_progress'}"
            :outlined="list.interactionType !== 'in_progress'"
            :color="list.interactionType === 'in_progress' ? '#2AD3BE' : '#1200D3'"
            @click="toggleInProgress"
          >
            <v-icon class="mr-2">{{ list.interactionType === 'in_progress' ? 'mdi-play-circle' : 'mdi-play-circle-outline' }}</v-icon>
            {{ list.interactionType === 'in_progress' ? $t('global:in.progress') : $t('global:initiate') }}
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="btn btn-primary ml-2"
                :class="{'btn-outlined': list.interactionType !== 'conclusion'}"
                :color="list.interactionType === 'conclusion' ? '#16AC99' : '#1200D3'"
                @click="toggleConclusionModal"
                v-bind:outlined="list.interactionType !== 'conclusion'"
                v-bind="attrs"
                v-on="on">
                <v-icon class="mr-1" :color="list.interactionType === 'conclusion' ? '#ffff' : '#1200D3'" v-if="list.interactionType === 'conclusion'">mdi-check-circle-outline</v-icon>
                <span>{{ list.interactionType === 'conclusion' ? $t('content.modal.details:concluded') : $t('content.modal.details:conclude')}}</span>
              </v-btn>
            </template>
            <span>{{ list.interactionType === 'conclusion' ? $t('content.toggle.conclusion.tooltip:undo') : $t('list.toggle.conclusion.tooltip:set.conclusion') }}</span>
          </v-tooltip>
          <div class="list-details--secondary-actions">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#1200D3" class="learning-card--share-button mx-2" v-on="on" v-bind="attrs" @click="showShareModal">mdi-share-variant</v-icon>
              </template>
              <span>{{ $t('list:share') }}</span>
            </v-tooltip>
            <v-menu v-if="userManagesContent(list.workspaceId)" bottom left absolute transition="slide-x-transition" min-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon color="#1200D3" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, i) in actionOptions"
                  :key="i"
                  @click="executeAction(action)"
                >
                  <v-list-item-title>{{ $t(`content.card.action.options:${action}`) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-row>
          <v-container>
            <v-container class="h8 mb-n4 px-2">
              {{$t('global:description')}}
            </v-container>
            <v-container v-if="!list.description" class="body--1 text-center pr-10 px-2">
              <p class="empty-skills--text lato font-16 neutral-medium mt-1">
                {{ $t('modal.list:empty.description') }}
              </p>
            </v-container>
            <v-container class="body--1 text-left pr-10 px-2">
              {{ list.description }}
            </v-container>
          </v-container>
        </v-row>
        <v-row>
          <v-container>
            <v-container class="h8 mb-n4 px-2">
              {{$t('global:skills')}}
            </v-container>
            <v-container class="empty-skills mt-2 px-1" v-if="!getFirstSkills || !getFirstSkills.length">
              <v-icon class="empty-skills--icon">
                mdi-stairs-box
              </v-icon>
              <p class="empty-skills--text lato font-16 neutral-medium mt-1">{{$t('modal.list:empty.skills')}}</p>
            </v-container>
            <v-container v-else>
              <v-btn rounded class="btn-secondary ma-1 skill-wrapper" v-for="skill in (showAllSkills ? getAllSkills : getFirstSkills)" :key="skill.id">
                <span class="skill-text"> {{skill.skillTitle}} </span>
              </v-btn>
              <v-btn v-if="!showAllSkills && remainingSkills > 0" @click="showAllSkills = true" fab small outlined class="btn-outlined-primary mx-1">
                +{{ remainingSkills }}
              </v-btn>
            </v-container>
          </v-container>
        </v-row>
        <v-row class="list-details--contents-wrapper">
          <v-container class="h8 mb-n4 px-2">
            {{$t('global:contents')}}
          </v-container>
          <v-container class="empty-contents mt-2" v-if="!contents || !contents.length">
            <v-img src="/assets/images/empty-box.png" width="100" class="mx-auto"/>
            <p class="lato font-16 neutral-medium mt-1 text-center">{{$t('modal.list:empty.contents')}}</p>
          </v-container>
          <v-container v-for="(content, index) in contents" :key="index">
            <content-card-wide :key="contents.length" :editable="!!userManagesContent(list.workspaceId)" :content="content" @hideModal="hideModal" @showModal="showModal" @remove="setContentToBeRemoved"/>
          </v-container>
        </v-row>
      </v-container>
    </v-card>
    <confirmation-modal
      :show="showRemovalModal"
      :title="$t('list.remove:title')"
      :description="$t('list.remove:info')"
      :confirmText="$t('global:delete')"
      :btnConfirmText="false"
      @confirm="removeList"
      :cancelText="$t('global:cancel')"
      @cancel="showRemovalModal = false"
    />
    <confirmation-modal
      :show="!!contentToBeRemoved"
      :title="$t('list.content.remove:title')"
      :description="$t('list.content.remove:info')"
      :confirmText="$t('global:delete')"
      :btnConfirmText="false"
      @confirm="removeContentFromList"
      :cancelText="$t('global:cancel')"
      @cancel="contentToBeRemoved = null"
    />
    <confirmation-modal
      :show="showConfirmToggleConclusion"
      :title="list.interactionType === 'conclusion' ? $t('content.card.modal.conclusion:undo.toggle.title') : $t('content.card.modal.conclusion:toggle.title')"
      :description="list.interactionType === 'conclusion' ? $t('list.card.modal.conclusion:undo.toggle.description') : $t('list.card.modal.conclusion:toggle.description')"
      :confirmText="$t('global:confirm')"
      @confirm="applyToggleContentConclusion"
      :cancelText="$t('global:cancel')"
      @cancel="toggleConclusionModal"
    ></confirmation-modal>
    <share-modal
      v-model="shareModal"
      :details="shareDetails()"
      @close="closeShareModal"
    >
      <image-card-type
        :card="{...list, type: 'list'}"
        defaultImage="/assets/images/list-cover-default.png"
      />
    </share-modal>
  </v-dialog>
  <router-view></router-view>
</div>
</template>

<style lang="scss">
.v-dialog-list-detail {
  z-index: 251 !important;
}
.list-details--wrapper {
  position: relative;
  .list-details-header {
    position: absolute;
    top: 170px;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 0) 150px, rgba(255, 255, 255, 1) 150px,rgba(255, 255, 255, 1) 100%);
    text-align: left;
    z-index: 15;
    .list-chip {
      color: $primary-medium;
    }
  }
  .top-right {
    .v-icon.mdi {
      color: $neutral-white;
    }
  }
  .list-details--image-wrapper {
    position: relative;
    .top-right .v-icon.mdi {
      color: $neutral-white;
    }
    .list-details--image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 1));
      z-index: 10;
    }
    .v-img {
      z-index: 5;
    }
  }
  .list-details--actions-btn-wrapper {
    display: flex;
    margin: 30px 0;
    .list-details--secondary-actions {
      display: flex;
      align-items: center;
      .learning-card--share-button {
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .list-details-header {
      top: 100px;
    }
    .list-details--image-wrapper {
      .list-details--image-overlay {
        max-height: 250px;
      }
      .v-image.v-responsive {
        max-height: 250px;
      }
    }
    .list-details--infos-wrapper {
      display: flex;
      flex-direction: column;
      > * {
        margin-bottom: 20px;
      }
    }
    .list-details--actions-btn-wrapper {
      flex-direction: column;
      margin: 30px 0 20px;
      > * {
        margin-left: 15px;
      }
      .list-details--secondary-actions {
        text-align: left;
        margin-top: 10px;
      }
    }
  }
  .skill-wrapper {
    max-width: 100%;
  }
  .skill-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .empty-skills {
    text-align: center;
    .empty-skills--icon {
      font-size: 40px;
    }
  }
}
.list-details-header {
  width: 100%;
  .list-details--owner-name {
      align-self: center;
    }
}
.list-details--info-container {
  display: flex;
}
.list-details--infos {
  margin-right: 35px;
  > * {
    display: inline;
    margin-right: 5px
  }
}
.list-details--actions-wrapper {
  margin-top: 30px;
}
.list-details--body-wrapper {
  text-align: left;
  .list-details--actions-wrapper {
    .list-details--infos-wrapper {
      display: flex;
    }
  }
}
.list-details--title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-family: $lato;
  font-weight: bold;
  font-size: 26px;
}
.list-details--contents-wrapper {
  padding: 12px;
}
.hide-content {
  display: none;
}
.list-details--insights-progress-wrapper {
  width: 100%;
  align-items: flex-end;
  margin-top: 5px;
  margin-left: 15px;
}
.list-details--insights-progress-percent {
  color: $neutral-darkest;
  font-family: $lato;
  font-weight: bold;
}
</style>
